/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    span: "span",
    h2: "h2",
    h3: "h3",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "/challenges-solutions-of-global-transportation-and-covid19/"
  }, "COVID-19"), " pandemic has proved to be a dead-end for many businesses. This pandemic can be given credit for ending the journey of several startups and enterprises across the world. However, when it comes to Super App, this pandemic has just been a minor roadblock that has diverted its journey."), "\n", React.createElement("div", null, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 56.49999999999999%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQL/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABqpV1Boss/8QAHhAAAQMEAwAAAAAAAAAAAAAAAQACEgMEERMhIjL/2gAIAQEAAQUCx2LorcFT9XA5YBD/xAAXEQADAQAAAAAAAAAAAAAAAAAQERIx/9oACAEDAQE/AXWj/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8BJ//EABkQAAIDAQAAAAAAAAAAAAAAAAAhAQIggf/aAAgBAQAGPwJPmKkI/8QAHBABAAICAwEAAAAAAAAAAAAAAQAhMaERQVFh/9oACAEBAAE/IVo4gq7TuNs9Dcw/YSCUobmNsT//2gAMAwEAAgADAAAAEFzP/8QAGBEBAQADAAAAAAAAAAAAAAAAAQARMVH/2gAIAQMBAT8QEM7SnL//xAAYEQACAwAAAAAAAAAAAAAAAAAAAREhgf/aAAgBAgEBPxCjcGn/xAAdEAEAAgICAwAAAAAAAAAAAAABABEhQTFRYXGB/9oACAEBAAE/ELSJSxpnTrEY25wCUudH2UqBZApOxcLYpPSbmQo7p5n/2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"covid-19-impact-on-south\"\n        title=\"\"\n        data-src=\"/static/4b87329386b6933b7fff326aa7e98402/a24e6/covid-19-impact-on-south.jpg\"\n        data-srcset=\"/static/4b87329386b6933b7fff326aa7e98402/a6b4f/covid-19-impact-on-south.jpg 200w,\n/static/4b87329386b6933b7fff326aa7e98402/a6407/covid-19-impact-on-south.jpg 400w,\n/static/4b87329386b6933b7fff326aa7e98402/a24e6/covid-19-impact-on-south.jpg 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "The pandemic has been a bittersweet experience for Super Apps. You might be thinking what is sweet about the damn pandemic right? Then you must remember that not all businesses are witnessing loss due to it. There are few businesses or industries which are witnessing an unprecedented rise in this period."), "\n", React.createElement(_components.p, null, "On-demand delivery services like ", React.createElement(_components.a, {
    href: "/on-demand-food-delivery-software-solution/"
  }, "food delivery"), ", ", React.createElement(_components.a, {
    href: "/on-demand-grocery-delivery-software-solution/"
  }, "grocery delivery"), ", and ", React.createElement(_components.a, {
    href: "/on-demand-courier-parcel-delivery-software-solution/"
  }, "parcel delivery"), " are some of these businesses. And Super App companies can take a sigh of relief since they provide these services."), "\n", React.createElement(_components.p, null, "So, one can say that despite colossal loss in ride-hailing, Super Apps can still grow by leveraging the boost in ", React.createElement(_components.a, {
    href: "/blog/covid-19-on-demand-delivery-business-opportunities/"
  }, "on-demand delivery businesses"), ". And this exactly what Super Apps are doing to overcome the pandemic."), "\n", React.createElement(_components.h2, {
    id: "south-east-asia--the-hub-for-super-app"
  }, "South-East Asia – The hub for Super App"), "\n", React.createElement(_components.p, null, "If we want to have a look at how Super App companies are changing their strategies amidst COVID-19 pandemic then we have to head towards South East Asia. This region is quite special since it’s the home to the two arch-rivals Super Apps – ", React.createElement(_components.a, {
    href: "https://www.grab.com/sg/",
    target: "_blank",
    rel: "nofollow"
  }, "Grab"), " and ", React.createElement(_components.a, {
    href: "https://www.gojek.com/",
    target: "_blank",
    rel: "nofollow"
  }, "Gojek"), "."), "\n", React.createElement(_components.p, null, "The rivalry between these Super Apps has been nothing short of sensational. Their rivalry has made South East Asia a hub for Super App."), "\n", React.createElement(_components.p, null, "If we talk about Gojek, then it started its journey as a bike ride-hailing service in Jakarta in the year 2010. After five years, in 2015, Gojek launched its app and has since expanded into a full-fledged Super App that offers 18 services which include finance, video streaming, food delivery, and many more. Gojek is Indonesia’s first unicorn which has a total valuation of $10bn."), "\n", React.createElement(_components.p, null, "Grab was launched in 2012 in Malaysia, two years after the inception of Gojek. It first started as a taxi-booking mobile app. Now, it has evolved into a Super App which offers food delivery, hotel reservations, financial services, etc. Grab is operational in eight countries and has a valuation of $14 bn."), "\n", React.createElement("div", null, React.createElement(_components.a, {
    href: "/blog/yelowsoft-delivers-a-super-app-to-mycar/"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 20%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAEABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAAB2qiMjL//xAAWEAADAAAAAAAAAAAAAAAAAAACEEH/2gAIAQEAAQUCor//xAAWEQADAAAAAAAAAAAAAAAAAAABEDH/2gAIAQMBAT8BNX//xAAWEQADAAAAAAAAAAAAAAAAAAABEDH/2gAIAQIBAT8BEX//xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAY/An//xAAYEAADAQEAAAAAAAAAAAAAAAAAAREhUf/aAAgBAQABPyFLes3b0rP/2gAMAwEAAgADAAAAEAfv/8QAFREBAQAAAAAAAAAAAAAAAAAAEDH/2gAIAQMBAT8Qkf/EABURAQEAAAAAAAAAAAAAAAAAABAx/9oACAECAQE/EKH/xAAZEAADAQEBAAAAAAAAAAAAAAAAAREhUXH/2gAIAQEAAT8QteHKO97cwWb9P//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"covid-19-impact-on-cta-1\"\n        title=\"\"\n        data-src=\"/static/e222d5df02a947395a6c7f710bfeaaa0/a24e6/covid-19-impact-on-cta-1.jpg\"\n        data-srcset=\"/static/e222d5df02a947395a6c7f710bfeaaa0/a6b4f/covid-19-impact-on-cta-1.jpg 200w,\n/static/e222d5df02a947395a6c7f710bfeaaa0/a6407/covid-19-impact-on-cta-1.jpg 400w,\n/static/e222d5df02a947395a6c7f710bfeaaa0/a24e6/covid-19-impact-on-cta-1.jpg 800w,\n/static/e222d5df02a947395a6c7f710bfeaaa0/8eeff/covid-19-impact-on-cta-1.jpg 801w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "changes-made-by-super-apps-to-overcome-the-covid-19-pandemic"
  }, "Changes made by Super Apps to overcome the COVID-19 pandemic"), "\n", React.createElement(_components.p, null, "As mentioned already, Pandemic is forcing ", React.createElement(_components.a, {
    href: "/blog/yelowsoft-introduces-super-app/"
  }, "Super Apps"), " to rethink on its strategy. Let’s have a look at how Gojek and Grab are plotting their strategies to overcome the ill effects of the pandemic."), "\n", React.createElement(_components.h3, null, "Terminating non-core services"), "\n", React.createElement(_components.p, null, "Super Apps in the ", React.createElement(_components.a, {
    href: "/blog/grab-defeat-uber-becomes-biggest-ride-hailing-company-in-southeast-asia/"
  }, "South-East Asia"), " region were going quite strongly before COVID-19 halted its progress. The economic catastrophe inflicted by this pandemic has forced big names like Grab and Gojek to reconsider their expansion plans."), "\n", React.createElement(_components.p, null, "This pandemic has shifted its plans from expansion towards consolidation. This major switch in plans has also forced Super Apps to take major steps."), "\n", React.createElement(_components.p, null, "These steps involve reorganization of their operations and termination of its low-performing services. They are also thinking of closing those services which can’t comply with the social-distancing measures."), "\n", React.createElement(_components.p, null, "Ticketing services and hotel booking are also one of those services which generate little revenue. That’s why it’s better if they are promoted less aggressively."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Read More: ", React.createElement(_components.a, {
    href: "/blog/partnership-of-taxi-companies-with-hotels-bars-and-restaurants/"
  }, "How taxi app can partner with hotels, restaurants, malls, and bars?")), "\n"), "\n", React.createElement(_components.p, null, "This can be seen in the Gojek’s decision where they laid off around 9% of its workforce. They also announced the closure of their GoLife service which offered on-demand massage and household cleaning services. Another service which faced the knife was GoFood Festivals which operates physical food halls."), "\n", React.createElement(_components.p, null, "Earlier, Grab decided to lay off around 360 employees which constitute approximately 5% of its workforce. Like Gojek, Grab also decided to close some of its non-core services."), "\n", React.createElement(_components.h3, null, "Focusing more on food delivery amidst the pandemic"), "\n", React.createElement(_components.p, null, "If we talk about the ", React.createElement(_components.a, {
    href: "/blog/impact-of-covid-19-on-ride-hailing-and-food-delivery-business/"
  }, "impact of COVID-19 on ride-hailing and food delivery business"), ", then it has been quite a contrast. On one hand, the ride-hailing industry is seeing unprecedented lows."), "\n", React.createElement(_components.p, null, "On the other hand, food delivery is witnessing a sudden spike as consumers prefer to order food and grocery online to adhere to the social distancing norms."), "\n", React.createElement(_components.p, null, "Food delivery services are now emerging as the main business line for Super Apps in South East Asia and Grab is the best example for it. Grab’s food delivery platform ", React.createElement(_components.a, {
    href: "https://food.grab.com/ph/en/",
    target: "_blank",
    rel: "nofollow"
  }, "GrabFood"), " which is only two years old has now overtaken its ride-hailing service line."), "\n", React.createElement(_components.p, null, "This huge transition also meant bad news for the ride-hailing drivers as many of them lost their jobs. However, as per Grab, around 150,000 switched from their ride-hailing job to become delivery drivers."), "\n", React.createElement("div", null, React.createElement(_components.a, {
    href: "/blog/why-you-must-introduce-food-delivery-business-in-your-existing-taxi-business/"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 20%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAEABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAIDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHTvAmKj//EABgQAAIDAAAAAAAAAAAAAAAAAAABAhIx/9oACAEBAAEFAk3eOH//xAAVEQEBAAAAAAAAAAAAAAAAAAAQQf/aAAgBAwEBPwGH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxABAAMAAAAAAAAAAAAAAAAAARARQf/aAAgBAQAGPwJMqf/EABkQAAIDAQAAAAAAAAAAAAAAAAERABAhMf/aAAgBAQABPyER3oVNOa//2gAMAwEAAgADAAAAEA/f/8QAFxEBAAMAAAAAAAAAAAAAAAAAARAhcf/aAAgBAwEBPxBC+R//xAAWEQADAAAAAAAAAAAAAAAAAAABEDH/2gAIAQIBAT8QFX//xAAYEAEBAQEBAAAAAAAAAAAAAAABEQAxUf/aAAgBAQABPxA3OAeG91kiuCG//9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"covid-19-impact-on-cta\"\n        title=\"\"\n        data-src=\"/static/eaa0b77f4badf4339458546ffdfb52da/a24e6/covid-19-impact-on-cta.jpg\"\n        data-srcset=\"/static/eaa0b77f4badf4339458546ffdfb52da/a6b4f/covid-19-impact-on-cta.jpg 200w,\n/static/eaa0b77f4badf4339458546ffdfb52da/a6407/covid-19-impact-on-cta.jpg 400w,\n/static/eaa0b77f4badf4339458546ffdfb52da/a24e6/covid-19-impact-on-cta.jpg 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.p, null, "Grab is not the only Super App giant to see this trend, its arch-rival Gojek is also witnessing something similar."), "\n", React.createElement(_components.p, null, "As per Gojek’s co-CEO, Kevin Aluwi, “Many industries were directly affected by the Covid-19 pandemic. Notably, witnessed a decline in use as Indonesians began to self-isolate,”."), "\n", React.createElement(_components.p, null, "“However, the isolation period has simultaneously enabled expansion in other industries, such as food, grocery, and package deliveries, as people turned to mobile and online purchasing options.” He added."), "\n", React.createElement(_components.p, null, "This shift is not just a South-East Asian phenomenon as it’s been witnessed across the world. Even the ", React.createElement(_components.a, {
    href: "https://www.cnbc.com/2020/07/06/uber-buys-postmates-food-delivery-service.html",
    target: "_blank",
    rel: "nofollow"
  }, "acquisition of Postmates by Uber for $2.65 billion"), " indicates as to how on-demand food delivery has become so significant in the COVID-19 era."), "\n", React.createElement(_components.p, null, "But this sudden rise of ", React.createElement(_components.a, {
    href: "/blog/things-to-consider-before-developing-an-on-demand-food-delivery-app/"
  }, "food delivery"), " app doesn’t mean that it’s game over for the ride-hailing businesses. Experts believe that ride-hailing will remain a crucial component of the super apps once the effect of pandemic eases out."), "\n", React.createElement(_components.h3, null, "Super Apps looking to increase their financial footprints"), "\n", React.createElement(_components.p, null, "Financial services are another sector which is expected to aid Super App to move forward amidst the pandemic. Both Gojek and Grab already have ", React.createElement(_components.a, {
    href: "https://www.gopay.com/en/",
    target: "_blank",
    rel: "nofollow"
  }, "GoPay"), " and ", React.createElement(_components.a, {
    href: "https://www.grab.com/sg/pay/",
    target: "_blank",
    rel: "nofollow"
  }, "GrabPay"), " that can process payments for their ride-hailing bookings."), "\n", React.createElement(_components.p, null, "Now, these Super Apps are looking to expand their financial services into online payments, point-of-sale, ride insurance for drivers and riders, business loans for MSMEs, travel insurance, etc."), "\n", React.createElement(_components.p, null, "The boost in the e-commerce caused due to the pandemic has encouraged the South-East Asian market leaders to make moves for expanding their financial footprints."), "\n", React.createElement(_components.p, null, "For example, Gojek finalized its acquisition of Moka (a local Indonesian payments startup) for an estimated deal of worth $130m. Furthermore, it was confirmed that PayPal and Facebook both were participating in the Gojek’s Series F round in which they raised approximately $3bn."), "\n", React.createElement(_components.p, null, "According to analysts, these could be strategic investments by these US companies to develop Gojek’s payments business."), "\n", React.createElement(_components.p, null, "If we talk about Grab, then it’s in the partnership with Singtel which is a well-known telco in Singapore. Singtel is also competing for one of the two banking licenses which Monetary Authority of Singapore is going to allocate by the end of the year."), "\n", React.createElement(_components.h2, {
    id: "what-can-super-app-do-overcome-covid-19-pandemic"
  }, "What can Super App do overcome COVID-19 pandemic"), "\n", React.createElement(_components.p, null, "One might think that Super Apps can offer discounts in order to sustain in the pandemic. However, it’s not the case. It’s because, in 2019, both Gojek and Grab were unprofitable despite their continued growth as they continued to offer discounts to their users."), "\n", React.createElement(_components.p, null, "One can understand the perspective of these Super Apps as they want to offer discounts to stimulate usage and save themselves from being cannibalised by their rival."), "\n", React.createElement(_components.p, null, "However, one must also remember that discounts alone can’t win the customers’ loyalty since customers tend to switch the service provider which offers them a better deal. And the same is true for drivers as well."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Read More: ", React.createElement(_components.a, {
    href: "/blog/go-beyond-ride-hailing-with-super-app/"
  }, "Expand your business beyond ride-hailing with a Super App")), "\n"), "\n", React.createElement(_components.p, null, "Apart from offering discounts, both Super Apps should focus on diversifying themselves in their service offerings and infrastructure to remain relevant and competitive in the long run. For an instance, Gojek and Grab can build new and advanced infrastructure like autonomous vehicles (AVs) to reduce operational expenditure."), "\n", React.createElement(_components.p, null, "Grab did something similar when it signed a partnership with an AV startup Nunotomy for the purpose of trying out passenger AV services in some areas of Singapore. Later in 2018, it came up with outlined plans that provided with the roadmap to commercialize the Robo-taxi services by the year 2022."), "\n", React.createElement(_components.p, null, "However, recent studies also show that Singapore is the only country where such services could be launched due to its AV regulations. On the other hand, other market lacks government support and required infrastructure to commercialize such services on a big scale."), "\n", React.createElement(_components.h2, {
    id: "conclusion"
  }, "Conclusion"), "\n", React.createElement(_components.p, null, "It won’t be right to say that Super Apps are immune to the effects of COVID-19. However, it’s safe to say that unlike ride-hailing businesses, Super Apps do have a plan B. In this article, we discussed what those Plan Bs are for both Grab and Gojek."), "\n", React.createElement(_components.p, null, "If you’re a ride-hailing business, then it’s not the end of the story for you as of now. It’s because even you have an excellent opportunity to evolve your services into a Super App."), "\n", React.createElement(_components.p, null, "Sounds difficult? What if I tell you that there’s a ready-made ", React.createElement(_components.a, {
    href: "/manage-your-multiple-businesses-with-super-app/"
  }, "Super App solution"), " that can make it possible for you in no time?"), "\n", React.createElement(_components.p, null, "Yes, Yelowsoft is world’s most advanced Super App solution that comes with state-of-the-art technology and advanced features."), "\n", React.createElement(_components.p, null, "With this solution in place, you can offer multiple services from a single app. You can offer food delivery, grocery delivery, parcel delivery, cargo delivery, and ride-hailing from the same app."), "\n", React.createElement(_components.p, null, "This also provides you with the opportunity to make up your ride-hailing losses by leveraging the boost in the on-demand delivery."), "\n", React.createElement(_components.p, null, "So, what are you waiting for? Contact Yelowsoft now to change your start up’s fortune amidst the pandemic."), "\n", React.createElement("div", null, React.createElement(_components.a, {
    href: "/contact/"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 20%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAEABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABYBAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAAB17F5QD//xAAWEAADAAAAAAAAAAAAAAAAAAABEDH/2gAIAQEAAQUCFX//xAAWEQEBAQAAAAAAAAAAAAAAAAAAATH/2gAIAQMBAT8Bi6//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAY/An//xAAZEAADAAMAAAAAAAAAAAAAAAAAAREhMXH/2gAIAQEAAT8hzasehKXp/9oADAMBAAIAAwAAABB8H//EABYRAQEBAAAAAAAAAAAAAAAAAAAhMf/aAAgBAwEBPxCmz//EABYRAAMAAAAAAAAAAAAAAAAAAAABMf/aAAgBAgEBPxB0UP/EABoQAQACAwEAAAAAAAAAAAAAAAEAIRExcUH/2gAIAQEAAT8QTkiGh7BYK5PQXKb8vXJ//9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"covid-19-impact-on-cta-3\"\n        title=\"\"\n        data-src=\"/static/8637746e5aadb3130547c9fb54edf624/a24e6/covid-19-impact-on-cta-3.jpg\"\n        data-srcset=\"/static/8637746e5aadb3130547c9fb54edf624/a6b4f/covid-19-impact-on-cta-3.jpg 200w,\n/static/8637746e5aadb3130547c9fb54edf624/a6407/covid-19-impact-on-cta-3.jpg 400w,\n/static/8637746e5aadb3130547c9fb54edf624/a24e6/covid-19-impact-on-cta-3.jpg 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
